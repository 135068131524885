<template>
    <div>
        <div style="width: 75%;height: 100%;float: left;background: #ffffff;">
            <div style="padding-left: 50px;margin-top: 20px;">
                <div>订单号：{{tableData.orderNo}}</div>
                <div>商品名称：{{tableData.description}}</div>
                <div>支付银行：{{tableData.bankName}}</div>
                <div>支付卡号：{{tableData.cardId}}</div>
                <div>银行类型：对公账户</div>
                <div>类型：延时分账</div>
                <div>下单时间：{{tableData.createTime}}</div>
                <div>支付时间：{{tableData.adaPayCreatedTime}}</div>

                <div><span style="color: red;">*</span>分账总金额: {{tableData.payAmt}}</div>
                <div><span style="color: red;">*</span>手续费: {{tableData.feeFlag}}</div>
                <div><span style="color: red;">*</span>可分账金额: {{tableData.realpayAmt}}</div>

                <div v-if="showPenny == true">
                    <div style="margin-left: 20px;margin-bottom: 20px;margin-top: 10px;">请确认分账人员及金额</div>
                    <div style="margin-left: 20px;">
                        <el-checkbox-group v-model="checked" @change="checkedUserList">
                            <!-- label绑定的值是要传的值 -->
                            <el-checkbox :label="item.value" v-for="(item,index) in settleAccountUserList" :key="index">
                                <!-- 视图上显示给用户看的值 -->
                                {{ item.label }}
                                <input style="width: 60px;"   :id="item.value" value="">
                            </el-checkbox>

                        </el-checkbox-group>

                        <div style="margin-top: 20px;">
                            <el-button type="primary" @click="paymentConfirmCreate">确 定</el-button>
                            <el-button @click="showPenny = false">取消</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "corp_div_members",
        data(){
            return{
                tableData:{},
                showPenny:false,
                paymentId:"",
                orderNo:"",
                payAmt:0,
                //复选框
                settleAccountUserList:[],
                checked:[],
            }
        },
        mounted() {
            this.orderNo = this.$route.params.formData; // 拿到上个页面传来的数据
            console.log(this.orderNo);
            this.getCorpAettlementInfo();
            this.querySettleAccountUserList();
        },
        methods:{
            //结算订单详情
            getCorpAettlementInfo(){
                var that = this;
                var params = {};
                params.orderNo = this.orderNo;
                that.newApi.getCorpAettlementInfo(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                        that.showPenny = true;
                    }
                })
            },

            //获取选中转账的人
            checkedUserList(value){
                this.checked = value;
            },

            //获取所有绑定结算账户列表
            querySettleAccountUserList(){
                var that = this;
                var params = {};
                that.newApi.querySettleAccountUserList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.settleAccountUserList = res.data;
                        console.log(that.settleAccountUserList);
                    }
                })
            },
            //分账确定
            paymentConfirmCreate(){
                var that = this;
                if(that.checked.length == 0){
                    that.utils.err("分账人员不能为空");
                    return false;
                }
                let money = 0;
                let userList = [];
                for (let i = 0; i < that.checked.length; i++) {
                    let obj = {};
                    obj.memberId = that.checked[i];
                    obj.amount = that.utils.keepTwoDecimalFull(document.getElementById(that.checked[i]).value);
                    money += parseFloat(obj.amount);
                    userList.push(obj);
                }
                if(money == 0){
                    that.utils.err("金额不能为空");
                    return false;
                }
                if(money > 0 && money != that.tableData.realpayAmt){
                    that.utils.err("分账金额不能超出" + that.tableData.realpayAmt);
                    return false;
                }
                var params = {};
                params.userList = JSON.stringify(userList);
                params.payAmt = that.utils.keepTwoDecimalFull(money);
                params.paymentId = that.tableData.paymentId;
                params.orderNo = that.tableData.orderNo;
                that.newApi.corpPaymentConfirmCreate(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.showPenny = false;
                        that.paymentId = "";
                        that.orderNo = "";
                        that.payAmt = 0;
                        that.utils.a('corp_member_cash');
                    }
                })

                console.log(params);
            }
        }
    }
</script>

<style scoped>

</style>